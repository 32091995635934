var logo = document.getElementById("logo-home"),
	herse = document.getElementById("herse"),
	nav = document.querySelector("#header"),
	content = document.getElementById("content"),
	animComplete = 0,
	menuLinks = document.querySelectorAll('a'),
	tags = document.querySelectorAll('.tag'),
	back = document.getElementById("background");

const currentPage = window.location.href;
var selectedFilters = [];

import anime from 'animejs/lib/anime.es.js';
import List from 'list.js';

// Intro

if (typeof(herse) != 'undefined' && herse != null){
function homeAnim(){
	var herseHeight = herse.offsetHeight,
		logoAnim = document.getElementById("logo-home-anim"),
		logoTop = window.pageYOffset + logoAnim.getBoundingClientRect().top;

    if(sessionStorage.getItem('animComplete') == null){
    anime({
		targets: '#herse',
		translateY: - (herseHeight/3)*2,
		duration: 4000,
		easing:'easeInOutCubic'
	});
	anime({
		targets: '#background',
		translateY: -200,
		scale: 3.2,
		duration: 4000,
		delay: 3600,
		easing:'easeInOutCubic'
	});
	anime({
		targets: '#logo-home-anim',
		translateY: -((logoTop) - (40)),
		scale: [0.1, 1],
		duration: 4800,
		delay: 3600,
		easing:'easeInOutCubic',
		complete: function(anim) {
	  		logo.classList.add("logo-top");
	  		nav.classList.add("in");
	  		content.classList.add("in");
	  		back.classList.add("back");
	  		logoAnim.classList.add("back");
	  		contentMargin();
	  	}

	});
	anime({
		targets: '#logo-home-anim',
		color: '#ff0000',
		duration: 6000,
		delay: 2400,
	});
    sessionStorage.setItem('animComplete', 1);

	}else if(sessionStorage.getItem('animComplete') == 1){
	anime({
		targets: '#herse',
		translateY: - (herseHeight/3)*2,
		duration: 0,
		delay: 0,
		easing: 'easeInOutExpo'
	});
	anime({
		targets: '#background',
		translateY: -200,
		scale: 3.2,
		duration: 0,
		delay: 0,
		easing:'easeInOutCubic'
	});
	anime({
		targets: '#logo-home-anim',
		translateY: -(logoTop - 94),
		scale: 8,
		duration: 0,
		delay: 0,
		easing:'easeInOutCubic',
		complete: function(anim) {
	  		logo.classList.add("logo-top");
	  		nav.classList.add("in");
	  		content.classList.add("in");
	  		back.classList.add("back");
	  		logoAnim.classList.add("back");
	  		contentMargin();
	  	}

	});
	anime({
		targets: '#logo-home-anim',
		color: '#ff0000',
		duration: 0,
		delay: 0,
	});
	}	
}

document.addEventListener("DOMContentLoaded", function(){
    homeAnim();
});
}

// liens

document.addEventListener("readystatechange", function() {
    for (var i = 0; i < menuLinks.length; i++) {
        if(menuLinks[i].href === currentPage){
            menuLinks[i].classList.add('active');
        }
    }
})

// Logo reload

logo.addEventListener("click", function(){
	sessionStorage.clear();
	document.location.href="https://www.simonheller.fr";
	// document.location.href="http://localhost:8080";
});

// Marge contenu

document.addEventListener("DOMContentLoaded", function(){
    contentMargin();
});

function contentMargin(){
	var navMainHeight = nav.getBoundingClientRect().height;
	content.style.marginTop = navMainHeight - 11 + 'px';
}

// Tags

for (var i = 0; i < tags.length; i++) {
	tags[i].addEventListener("click", function() {
		if(this.classList.contains('active') && window.location.href.indexOf('type') != -1){
			this.href="https://www.simonheller.fr";
		} else if(this.classList.contains('active') && window.location.href.indexOf('topic') != -1){
			this.href="https://www.simonheller.fr";
		}
	})
}

// Array.prototype.diff = function(arr2) {
//     		var ret = [];
//     		for(var i in this) {   
//         		if(arr2.indexOf(this[i]) > -1){
//             		ret.push(this[i]);
//         		}
//     		}
//     		return ret;
// };

// for (var i = 0; i < tags.length; i++) {
// 	var options = {valueNames: [ { data: ['id'] } ]};
// 	var listObj = new List('content', options);
// 	tags[i].addEventListener("click", function() {
// 		for (var i = 0; i < tags.length; i++) {
// 			tags[i].style.display = "none";
// 		}
// 		if (this.classList.contains('active') == false) {
// 			this.classList.add('active');
// 			selectedFilters.push(this.dataset.id);
// 		} else{
// 			this.classList.remove('active');
// 			const index = selectedFilters.indexOf(this.dataset.id);
// 			if (index > -1) {
//   				selectedFilters.splice(index, 1);
// 			}
// 		}
// 		console.log(selectedFilters);
// 		listObj.filter(function(item) {
// 			var source = item.values().id;
// 			var success = selectedFilters.every(function(val) {
//     			return source.indexOf(val) !== -1;
// 			});
// 			if (success === true) {
//    				return true;
// 			} else {
//    				return false;
// 			}
// 		});
// 		if (selectedFilters.length == 0){
// 			listObj.filter();	
// 		};
// 		var projectItems = document.querySelectorAll('.project-item');
// 		var tagsOn = [].map.call(projectItems, function (el) {return el.getAttribute("data-id");});
// 		var tagsOnArr = tagsOn.flatMap(x => x.split(", "));
// 		var tagsInner = Array.prototype.slice.call(tags).map(function(x){return x.dataset.id});
// 		var compare = tagsOnArr.diff(tagsInner).map(function(value) { return '.' + value; });
// 		var compareList = compare.toString();
// 		var compareTags = document.querySelectorAll(compareList);
// 		for (var i = 0; i < compareTags.length; i++) {
// 			compareTags[i].style.display = "inline-block";
// 		}

// 	});
// };


